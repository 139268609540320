<template>
  <div class="blog-post__item">
    <div v-if="post.image" class="blog-post__img">
      <img :src="post.image.url" alt="Post title">
    </div>
    <div class="blog-post__content">
      <div class="blog-post-code">
        <span class="blog-post-code__author">{{ post.author.full_name }}</span>
        <span class="blog-post-code__date">at {{ post.created_at | date }} </span>
      </div>
      <div class="blog-post__title text-left">
        <router-link :to="{name : 'BlogSingle', params : {slug : post.slug}}">
          {{ post.title }}
        </router-link>
      </div>
      <p class="blog-post__text text-left">{{ post.body | strLimit(150) }}</p>
      <router-link
        :to="{name: blogDetailsLink, params: {slug: post.slug}}"
        class="blog-post__button secondary white--text"
      >
        READ MORE
      </router-link>
    </div>
  </div>
</template>

<script>
import routeMap from '@/utils/routeMap';

export default {
  name: 'BlogSingle',
  props: {
    post: {
      type: Object,
    }
  },

  data: () => ({
    blogDetailsLink: routeMap.blog.preview.name
  }),

};
</script>

<style lang="scss" scoped src="./BlogSingle.scss"></style>
