<template>
  <XyzTransitionGroup
    :tag="tag"
    :xyz="animateClasses.join(' ')"
    :appear="initialAnimation"
  >
    <slot></slot>
  </XyzTransitionGroup>
</template>

<script>
export default {
  name: 'ListAnimation',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    duration: {
      type: [Number, String],
      validator: (value) => {
        const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
        if (allowed.includes(parseFloat(value))) return true;

        throw new Error(`Unsupported animation duration '${value}', allowed: ${allowed}`);
      }
    },
    initialAnimation: {
      type: Boolean,
      default: true
    },
    stackDisabled: {
      type: Boolean,
      default: false
    },
    stackDuration: {
      type: [Number, String],
      validator: value => {
        const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
        if (allowed.includes(parseFloat(value))) return true;

        throw new Error(`Unsupported stack duration '${value}', allowed: ${allowed}`);
      }
    },
    stackReverseOrder: {
      type: Boolean,
      default: false
    },
    directionIn: {
      type: String,
      default: 'down',
      validator: value => {
        const allowed = ['right', 'left', 'down', 'up', 'front', 'back'];

        if (allowed.includes(value)) return true;
        throw new Error(`Invalid direction '${value}', allowed: ${allowed}`);
      }
    },
    directionOut: {
      type: String,
      validator: value => {
        const allowed = ['right', 'left', 'down', 'up', 'front', 'back'];

        if (allowed.includes(value)) return true;
        throw new Error(`Invalid direction '${value}', allowed: ${allowed}`);
      }
    },
    directionScale: {
      type: String,
      validator: value => {
        const allowed = ['0', '1', '2', '3', '4', '5', '25%', '50%', '75%', '100%'];

        if (allowed.includes(value)) return true;
        throw new Error(`Invalid direction Scale '${value}', allowed: ${allowed}`);
      }
    },
    additionalAnimateClasses: {
      type: String,
    }
  },

  computed: {

    animationDuration() {
      let duration = 'duration';

      if (this.duration === undefined) return duration;

      duration += `-${this.duration}`;
      return duration;
    },

    animationDirection() {
      const inDirection = `in-${this.directionIn}`;
      const outDirection = `out-${this.directionOut === undefined ? this.directionIn : this.directionOut}`;

      let direction = [inDirection, outDirection];
      if (this.directionScale === undefined) return direction;

      direction[0] = `${inDirection}-${this.directionScale}`;
      direction[1] = `${outDirection}-${this.directionScale}`;
      return direction;
    },

    animateClasses() {

      /**
       * when stack enabled up / down animation will not work when any item added/removed from list
       * @type {string}
       */
      const stack = this.stackDisabled
        ? ''
        : this.stackReverseOrder ? 'stagger-rev' : 'stagger'
      ;

      const stackWithTiming = this.stackDuration === undefined
        ? stack
        : `${stack}-${this.stackDuration}`
      ;

      return [
        'fade',
        stackWithTiming,
        this.animationDuration,
        ...this.animationDirection,
        this.additionalAnimateClasses || ''
      ];
    }

  },
};
</script>
