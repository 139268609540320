<template>
  <router-link
    :to="blogDetailsLink"
    class="pa-0 ma-0"
  >
    <v-row no-gutters dense class="pa-2">
      <v-col cols="12">
        <img :src="post.image.url" alt="">
      </v-col>
      <v-col cols="12">
        <div class="text-h6 dark--text"> {{ post.title }}</div>
      </v-col>
    </v-row>
  </router-link>
</template>

<script>
import routeMap from '@/utils/routeMap';

export default {
  name: 'BlogSimple',
  props: {
    post: {
      type: Object,
    },
  },

  computed: {
    blogDetailsLink() {
      return {
        name: routeMap.blog.preview.name,
        params: {
          slug: this.post.slug
        }
      };
    }
  }
};
</script>
